<template>
  <o-container ref="container">
    <v-card elevation="2" shaped class=" fill-height">
      <v-card-title v-if="action === ``" class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto ">
          {{ title }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
          <o-button
            :busy="busy"
            color="green"
            icon="mdi-plus"
            type="label-icon"
            label="Add New"
            @on-click="add()"
          />

          <o-button
            :busy="busy"
            color="warning"
            icon="mdi-filter"
            type="label-icon"
            label="Filter"
            @on-click="showFilter = true"
          />

          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-sync"
            type="label-icon"
            label="Refresh"
            @on-click="reset()"
          />
        </div>
      </v-card-title>
      <v-divider v-if="action === ``" />

      <!-- table -->
      <v-card-text v-if="action === ``" class="text-primary">
        <o-table
          ref="table"
          type="rest"
          :lazy="true"
          :busy="busy"
          :has-paging="true"
          :height="getHeight(442)"
          :fixed-header="true"
          method="POST"
          :rest="{
            url: `/rest/intranet/list`,
            payload: listParameters,
          }"
          :headers="headers"
          @set-busy="setBusy"
          @on-item-edit="edit"
          @on-item-delete="remove"
        >
          <template v-slot:slot="row">
            <div
              v-if="row.prop.key === `user_name`"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.user_id > 0">
                {{ row.prop.item.user_name }} ({{
                  row.prop.item.user_id
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === `created_by_name`"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.created_by > 0">
                {{ row.prop.item.created_by_name }} ({{
                  row.prop.item.created_by
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === `updated_by_name`"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.updated_by > 0">
                {{ row.prop.item.updated_by_name }} ({{
                  row.prop.item.updated_by
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === `deleted_by_name`"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.deleted_by > 0">
                {{ row.prop.item.deleted_by_name }} ({{
                  row.prop.item.deleted_by
                }})
              </template>
            </div>
          </template>
        </o-table>
      </v-card-text>

      <v-card-title v-if="action !== ``" class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto">
          {{ `${action} - ${title}` }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
          <o-button
            :busy="busy"
            color="success"
            icon="mdi-content-save"
            type="label-icon"
            label="Save"
            @on-click="save()"
          />

          <o-button
            v-if="action === `edit` && model.deleted_at"
            :busy="busy"
            color="danger"
            icon="mdi-refresh"
            type="label-icon"
            label="Restore"
            @on-click="restore()"
          />

          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-keyboard-backspace"
            type="label-icon"
            label="Back"
            @on-click="action = ''"
          />
        </div>
      </v-card-title>

      <v-divider v-if="action !== ``" />
      
      <!-- editor -->
      <div v-if="action !== ``" class="ml-5 pb-5 mr-5 o-container-content">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" class="pa-0">
                <o-combo-box
                  v-if="action == 'edit'"
                  v-model="model.user_id"
                  :busy="busy"
                  label="User"
                  :items="users"
                />
              </v-col>
              <v-col cols="12" class="pa-0">
                <o-input
                  v-model="model.name"
                  :busy="busy"
                  label="Name"
                  :is-required="true"
                  :rules="[(v) => !!v || 'This is required']"
                />
              </v-col>
              <v-col cols="12" class="pa-0" 
              >
                <o-input
                  v-model="model.email"
                  :busy="busy"
                  label="Email"
                  :is-required="true"
                  :rules="[(v) => !!v || 'This is required']"
                />
              </v-col>
              <v-col cols="12" class="pa-0" v-if="action === 'edit'">
                <o-input
                  v-model="model.token"
                  :busy="busy"
                  label="Token"
                  :is-required="true"
                  :rules="[(v) => !!v || 'This is required']"
                />
              </v-col>
              <v-col cols="12" class="pa-0">
                <o-string-list
                  v-model="model.origin"
                  :busy="busy"
                  label="Origin"  
                />  
              </v-col>
              <v-col cols="12" class="pa-0">
                <o-check-box
                  v-model="model.active"
                  class="pt-4"
                  :busy="busy"
                  label="Active"
                />
              </v-col>
              <v-col cols="12" class="pa-0 pt-10" v-if="action === 'edit'">
                <o-input 
                  v-model="model.app_id"
                  :busy="busy"
                  label="AppID"
                  :is-required="true"
                  :rules="[(v) => !!v || 'This is required']"
                />
              </v-col>
              <v-col cols="6" class="pa-0" v-if="action === 'edit'">
                <o-input 
                  v-model="model.client_id"
                  :busy="busy"
                  label="Client ID"
                  :is-required="true" 
                />
              </v-col>
               <v-col cols="6" class="pa-0 pl-10" v-if="action === 'edit'">
                <o-input 
                  v-model="model.client_secret"
                  :busy="busy"
                  label="Client Secret"
                  :is-required="true" 
                />
              </v-col>
              <v-col cols="12" class="pa-0">
                <o-input
                  v-model="model.notes"
                  :busy="busy"
                  label="Notes"
                  type="textarea"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </div>

      <!-- filter -->
      <o-drawer
        v-if="!busy"
        :auto-close="true"
        :actions="[
          {
            key: 'on-item-reset',
            label: 'RESET',
            icon: 'mdi-refresh',
            color: 'warning',
          },
          {
            key: 'on-item-search',
            label: 'SEARCH',
            icon: 'mdi-text-box-search-outline',
            color: 'success',
          },
        ]"
        button-icon="mdi-filter-plus-outline"
        :title="`FILTERS`"
        :use-button="false"
        width="50vw"
        :visible="showFilter"
        @on-item-search="
          showFilter = false;
          reset();
        "
        @on-item-reset="
          showFilter = false;
          reset(true);
        "
        @on-close="showFilter = false"
      >
        <v-row class="pa-0 ma-0"> 
          <v-col cols="12" class="pa-0">
            <o-input
              v-model="parameters.name"
              :busy="busy"
              label="Name" 
            />
          </v-col>
          <v-col cols="12" class="pa-0" 
          >
            <o-input
              v-model="parameters.email"
              :busy="busy"
              label="Email"
            />
          </v-col> 
        </v-row>
      </o-drawer>
    </v-card>
  </o-container>
</template>

<script>
import RestApi from "@/services/RestApi";
import { mapGetters } from "vuex";
import md5 from "md5";

export default {
  name: `APIKey`,
  mixins: [RestApi],
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: `API Keys`,
  },
  data() {
    return {
      title: `API Keys`,
      busy: false,
      strictFilter: false,
      action: ``,
      valid: false,
      baseModel: {
        id: 0,
        user_id: 0,
        name: "",
        email: "",
        origin: [],
        token: "",
        app_id: "",
        client_id: "",
        client_secret: "",
        active: false,
        notes: "",
        created_at: "",
        created_by: 0,
        updated_at: "",
        updated_by: 0,
        deleted_at: null,
        deleted_by: 0,
      },
      model: {},
      originalModel: {},
      parameters: {
        name: "",
        email: ""
      },
      headers: [
        {
          text: `#`,
          align: `end`,
          value: `row_no`,
          type: `int`,
          width: 100,
        }, 
        {
          text: 'User',
          align: 'start',
          sortable: false,
          value: 'user_name',
          type: 'slot',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
          type: 'text',
        },
        {
          text: 'Email',
          align: 'start',
          sortable: false,
          value: 'email',
          type: 'text',
        },
        {
          text: 'Origin',
          align: 'start',
          sortable: false,
          value: 'origin',
          type: 'string-array',
        },
        {
          text: 'Token',
          align: 'start',
          sortable: false,
          value: 'token',
          type: 'text',
        },
        {
          text: 'Active',
          align: 'center',
          sortable: false,
          value: 'active',
          type: 'boolean',
        }, 
         {
          text: 'AppID',
          align: 'start',
          sortable: false,
          value: 'app_id',
          type: 'text',
        },
        {
          text: `Created Date`,
          align: `start`,
          value: `created_at`,
          width: 120,
          type: `date-time`,
        },
        {
          text: `Created By`,
          align: `start`,
          value: `created_by_name`,
          width: 120,
          type: `slot`,
        },
        {
          text: `Updated Date`,
          align: `start`,
          value: `updated_at`,
          width: 120,
          type: `date-time`,
        },
        {
          text: `Updated By`,
          align: `start`,
          value: `updated_by_name`,
          width: 120,
          type: `slot`,
        },
        {
          value: `action`,
          type: `action`,
          width: 100,
          actions: [
            {
              key: `on-item-edit`,
              label: `Edit`,
              icon: `mdi-pencil-box-outline`,
              color: `success`,
            },
            {
              key: `on-item-delete`,
              label: `Delete`,
              icon: `mdi-trash-can-outline`,
              color: `danger`,
            },
          ],
        },
        {
          text: `Deleted Date`,
          align: `start`,
          value: `deleted_at`,
          width: 120,
          type: `date-time`,
        },
        {
          text: `Deleted By`,
          align: `start`,
          value: `deleted_by_name`,
          width: 120,
          type: `slot`,
        },
      ],
      accounts: [],
      users: [], 

    };
  },
  computed: {
    ...mapGetters([`loggedInUser`]),
  },
  watch: {},
  created() {
    this.listParameters.fields = [
      `ROW_NUMBER() OVER(ORDER BY a.deleted_at DESC, a.updated_at DESC) row_no`,
      `a.*`,
      `concat(b.last_name, ', ', b.first_name) as user_name`,
      `concat(c.last_name, ', ', c.first_name) as created_by_name`,
      `concat(u.last_name, ', ', u.first_name) as updated_by_name`,
      `concat(d.last_name, ', ', d.first_name) as deleted_by_name`,
    ];
    this.listParameters.sources = {
      main: `api_keys a`,
      children: [
        {
          name: `users b`,
          on: `a.user_id = b.id`,
        }, 
        {
          name: `users c`,
          on: `a.created_by = c.id`,
        },
        {
          name: `users u`,
          on: `a.updated_by = u.id`,
        },
        {
          name: `users d`,
          on: `a.deleted_by = d.id`,
        },
      ],
    };
    this.listParameters.orders = [
      {
        sort: `a.deleted_at`,
        dir: `DESC`,
      },
      {
        sort: `a.updated_at`,
        dir: `DESC`,
      },
    ];

    for (const [k, v] of Object.entries(this.parameters)) { 
      if (this.$route.query[k]) {
        this.parameters[k] = this.$route.query[k];
      }
    }    

    //this.parameters.account_id = this.stringToNumberArray(this.parameters.account_id);
    //this.parameters.advertiser_id = this.stringToNumberArray(this.parameters.advertiser_id);

    const wheres = this.setParameters();
    if (wheres.length > 0)
      this.listParameters.wheres = [
        wheres.join(` ${this.strictFilter ? ` AND ` : ` OR `} `),
    ];
  },
  async mounted() {
    this.accounts = await this.getList(
      ["account_id as id, account_name as name"],
      "accounts",
      [],
      [],
      "name"
    );
    this.users = await this.getList(
      ["id, concat(last_name, ', ', first_name) as name"],
      "users",
      ["deleted_at is null"],
      [],
      "name"
    );
    
  },
  methods: {
    add() {
      this.action = `add`;
      this.model = _.cloneDeep(this.baseModel);
    },
    edit(item) {
      this.model = _.cloneDeep(item);
      this.originalModel = _.cloneDeep(item); 
      this.model.app_id = md5(md5(`${(new Date()).getMilliseconds()}-client_id`)).substring(0, 6)
      this.model.client_id = md5(md5(`${(new Date()).getMilliseconds()}-client_id`)).substring(0, 12)
      this.model.client_secret = md5(md5(`${(new Date()).getMilliseconds()}-client_secret`))
      this.action = `edit`;
    },    
    async save() {
      if (!this.$refs.form.validate()) return false;
      const errors = [];
      const model = _.cloneDeep(this.model);
      
      if (this.action === `add`) {   
        const url = `/rest/api_keys`;
         this.model.created_by = this.loggedInUser.id;
         await this.postData(url, this.model)
          .then((response) => {
            this.$refs.container.snackBar(
              `Successfully added ${model.name} (${model.email}).`,
              "green",
              2000
            );
            this.reset();
          })
          .catch((err) => {
            errors.push(err.data.message);
          });
      }
      if (this.action === "edit") {       
        this.$refs.container.snackBar(
              `SOOON!!!!`,
              "red",
              2000
            );    
        /*const url = "/rest/intranet/query"; 

        const parameters = _.cloneDeep(this.updateParameters);
        parameters.table = 'api_keys';
        parameters.fields.push({field: 'user_id', value: this.model.user_id });
        parameters.fields.push({field: 'name', value: this.model.name });
        parameters.fields.push({field: 'email', value: this.model.email });
        parameters.fields.push({field: 'origin', value: JSON.stringify(this.model.origin) });
        parameters.fields.push({field: 'token', value: this.model.token });
        parameters.fields.push({field: 'app_id', value: this.model.app_id });
        parameters.fields.push({field: 'client_id', value: this.model.client_id });
        parameters.fields.push({field: 'client_secret', value: this.model.client_secret });
        parameters.fields.push({field: 'active', value: this.model.active });
        parameters.fields.push({field: 'notes', value: this.model.notes }); 
        parameters.wheres.push({ field: `id`, value: this.model.id });

        await this.postData(url, parameters)
          .then((response) => {
            this.$refs.container.snackBar(
              `Successfully updated`,
              "green",
              2000
            ); 
          })
          .catch((err) => {
            errors.push(err.data.message);
          });*/
      }

      if (errors.length > 0) {
        this.$refs.container.snackBar(
          `Error updating item: ${errors.join(",")}`,
          "red",
          2000
        );
      } else {
         this.users = await this.getList(
          ["id, concat(last_name, ', ', first_name) as name"],
          "users",
          ["deleted_at is null"],
          [],
          "name"
        );
        this.action = ``;
        this.reset();
      }
      /*
      if (!this.$refs.form.validate()) return false;
      const errors = [];
      const url = `/rest/api_keys`;
      const model = _.cloneDeep(this.model);      
  
      if (this.action === `add`) {   
         this.model.created_by = this.loggedInUser.id;
         await this.postData(url, this.model)
          .then((response) => {
            this.$refs.container.snackBar(
              `Successfully added ${model.name} (${model.email}).`,
              "green",
              2000
            );
            this.reset();
          })
          .catch((err) => {
            errors.push(err.data.message);
          });
      }
      if (this.action === `edit`) {
        this.model.updated_by = this.loggedInUser.id;
        const model = _.cloneDeep(this.model);

        this.putData(`${url}/${this.model.id}`, this.model)
          .then((response) => {
            this.$refs.container.snackBar(
              `Successfully updated ${model.name} (${model.email}).`,
              "green",
              2000
            );
            this.reset();
          })
          .catch((err) => {
            errors.push(err.data.message);
          });
      }

      if (errors.length > 0) {
        this.$refs.container.snackBar(
          `Error updating item: ${errors.join(",")}`,
          `red`,
          2000
        );
      } else {
        this.users = await this.getList(
          ["id, concat(last_name, ', ', first_name) as name"],
          "users",
          ["deleted_at is null"],
          [],
          "name"
        );
        this.action = ``;
      }*/
    },
    async remove(item) {
      this.model = _.cloneDeep(item);
      await this.$refs.container
        .confirm(
          `Confirmation`,
          `You are deleting <strong>${this.model.name} (${this.model.id})</strong>. Do you want to continue?`,
          `red`
        )
        .then(async (ans) => {
          if (ans) {
            const parameters = _.cloneDeep(this.updateParameters);
            const url = `/rest/intranet/query`;
            parameters.table = 'api_keys';
            parameters.fields.push({
              field: `deleted_by`,
              value: this.loggedInUser.id
            });
            parameters.fields.push({
              field: `deleted_at`,
              value: new Date().toUTCString(),
            });
            parameters.wheres.push({ field: `id`, value: this.model.id });
            await this.postData(url, parameters)
              .then((response) => {
                this.$refs.container.snackBar(
                  `The record of ${this.model.agency_name} (${this.model.agency_id}) was successfully deleted.`,
                  `red`,
                  2000
                );
                this.reset();
              })
              .catch((err) => {
                this.$refs.container.snackBar(
                  `Error removing item: ${err.data.message}`,
                  `red`,
                  2000
                );
              });
          }
        });
    },
    async restore() {
      await this.$refs.container
        .confirm(
          `Confirmation`,
          `You are restore <strong>${this.model.name} (${this.model.id})</strong>. Do you want to continue?`,
          `red`
        )
        .then(async (ans) => {
          if (ans) {
           const parameters = _.cloneDeep(this.updateParameters);
            const url = `/rest/intranet/query`;
            parameters.table = 'api_keys';
            parameters.fields.push({
              field: `updated_by`,
              value: this.loggedInUser.id
            });
            parameters.fields.push({
              field: `updated_at`,
              value: new Date().toUTCString(),
            });
            parameters.fields.push({
              field: `deleted_by`,
              value: null
            });
            parameters.fields.push({
              field: `deleted_at`,
              value: null
            });
            parameters.wheres.push({ field: `id`, value: this.model.id });
            await this.postData(url, parameters)
              .then((response) => {
                this.$refs.container.snackBar(
                  `The record of ${this.model.name} (${this.model.id}) was successfully restored.`,
                  `red`,
                  2000
                );
                this.reset();
              })
              .catch((err) => {
                this.$refs.container.snackBar(
                  `Error removing item: ${err.data.message}`,
                  `red`,
                  2000
                );
              });
          }
        });
    },
    async reset(clear) {
      await this.clearQueryString();
      this.listParameters.wheres = [];
      if (clear) {
        this.parameters = {
          name: "",
          email: ""
        };
      }
      const wheres = this.setParameters();
      if (wheres.length > 0)
        this.listParameters.wheres = [
          wheres.join(` ${this.strictFilter ? ` AND ` : ` OR `} `),
        ];

      await this.changeQueryString(this.parameters);

      this.action = ``;
      this.model = _.cloneDeep(this.baseModel);
      setTimeout(async () => {
        this.$refs.table.refresh();
      }, 200);
    }, 
    setParameters() {
      const wheres = [];
         
      if (this.parameters.name != ``) {
        wheres.push(this.getWhereString(`a.name`, this.parameters.name));
      }
      if (this.parameters.email != ``) {
        wheres.push(this.getWhereString(`a.email`, this.parameters.email));
      }
      return wheres;
    }
  },
};
</script>
<style lang="scss" scoped>
.o-container-content {
  min-height: calc(100vh - 278px);
  max-height: calc(100vh - 278px);
  overflow-y: scroll;
}
</style>
