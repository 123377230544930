<template>
  <ApiKey dialog-name="API Keys" />
</template>
<script>
import ApiKey from '@/views/app/api/Key'

export default {
  name: 'ApiKeyWrapper',
  components: {
    ApiKey
  }
}
</script>
